.resetButton {
  text-decoration: underline;
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  align-items: center;
}

.resetButton:disabled {
  color: #868686;
  text-decoration: none;
}

.info {
  font-weight: bold;
  margin-top: 1em;
}

.spinner {
  margin-left: 1ch;
}
