.form {
  display: flex;
  flex-direction: column;
}

.laser {
  margin-bottom: 24px;
}

.submit:disabled {
  transform: translateY(-33px); /* Trial and error got me this value. */
}

.instructions {
  margin-top: 24px;
}
